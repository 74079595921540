<div id="auth-width">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        <div class="login-banner">
          <img src="../../../../assets/Indie images/Indie Town.png" alt="login-banner" class="img-fluid">
        </div>
      </div>
      <div class="col-md-6">
        <div class="login-field">
          <div class="login-box">
            <h3>Sign In</h3>
            <form [formGroup]="loginForm" (submit)="login()">
              <div class="form-group">
                <label>Email Address *</label>
                <span class="input-icon"><img src="../../../../assets/icons/email.svg" alt=""
                    style="margin-top: -10px;"></span>
                <input type="email" class="form-control" placeholder="Email" id="email" formControlName="email">
                <div *ngIf="submitted && f.email.errors" class="alert alert-danger">
                  <span *ngIf="f.email.errors.required">
                    Email Required
                  </span>
                  <span *ngIf="f.email.errors.email">
                    Invalid Email
                  </span>
                </div>
              </div>

              <!-- <mat-icon matSuffix>visibility</mat-icon> -->
              <!-- <div class="form-group">
                <label for="email">Password *</label>
                <span class="input-icon"><img src="../../../../assets/icons/password.svg" alt="" style="margin-top: -10px;">
                  <mat-icon matSuffix style="margin-left:350px;padding-left: 35px" (click)="togglePasswordVisibility()">{{showPassword?'visibility':'visibility_off'}}</mat-icon>
                </span>
                <input matInput [type]="showPassword ? 'text' : 'password'" type="password" class="form-control" placeholder="Password" id="password"
                    formControlName="password">
                  <div *ngIf="submitted && f.password.errors" class="alert alert-danger">
                    <span *ngIf="f.password.errors.required">
                      Password Required
                    </span>
                   
                    <span *ngIf="f.password.errors.minlength">
                      Password must be of six or more characters
                    </span>
                    
                    
                </div> -->
              <div class="form-group">
                <label>Password *</label>
                <span class="input-icon"><img src="../../../../assets/icons/password.svg" alt=""
                    style="margin-top: -10px;"></span>
                <input [type]="hide ? 'password' : 'text'" class="form-control" placeholder="Password"  id="password-input"
                  formControlName="password" (keyup)="removeSpacesFromBegining($event)">
                <mat-icon class="placeholder" (click)="myFunction()">{{hide ? 'visibility_off' :
                  'visibility'}}</mat-icon>
                <div *ngIf="submitted && f.password.errors" class="alert alert-danger">
                  <span *ngIf="f.password.errors.required">
                    Password Required
                  </span>

                  <span *ngIf="f.password.errors.minlength">
                    Password must be of six or more characters
                  </span>
                </div>


                <!-- style="width:30%;margin-left: 68%;" -->

                <div class="forgot-pass mt-2 d-inline-block float-right mb-4" routerLink="/auth/forgot-password">Forgot Password?</div>
              </div>

              <button type="submit" class="btn btn-primary">Sign In</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>