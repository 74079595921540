import { ManageCommunityService } from "./views/manage-community/manage-community.service";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

// import { MatFormFieldModule } from '@angular/material/form-field';
// import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
// import { MatIconModule } from '@angular/material/icon';


import { ImageCropperModule } from "ngx-image-cropper";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { NgHttpLoaderModule } from "ng-http-loader";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { ModalModule } from "ngx-bootstrap/modal";
import { CustomFormsModule } from "ng2-validation";

import { NgxSliderModule } from "@angular-slider/ngx-slider";

import { AppRoutingModule } from "./app-routing.module";
import { AuthModule } from "./auth/auth.module";
import { SharedModule } from "./shared/shared.module";

import { AppComponent } from "./app.component";
import { AuthInterceptor } from "./_helpers/auth.interceptor";
import { AuthService } from "./auth/services/auth.service";
import { SharedService } from "./shared/services/shared.service";
import { ViewsComponent } from "./views/views.component";
import { AuthGuard } from "./_helpers/auth.guard.service";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { SessionService } from "./shared/services/session.service";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { environment } from "src/environments/environment";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { MatSelectModule } from "@angular/material/select";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";

const config: SocketIoConfig = { url: environment.BaseChatUrl, options: {} };

import { DashboardService } from "./dashboard/services/dashboard.service";
import { SafeHtmlPipe } from "./shared/pipes/safe-html.pipe";
import { ManageCaregiversService } from "./views/manage-reported/manage-reported.service";
import { ManageClientsService } from "./views/manage-clients/manage-clients.service";
import { ManageService } from "./views/manage-service/manage-service.service";
import { ManageConvenienceService } from "./views/manage-community1/manage-community1.service";
import { CodegenerationService } from "./views/code-generation/code-generation.service";
import { ManageGenresService } from "./views/manage-genres/manage-genres.service";


@NgModule({
  declarations: [
    AppComponent,
    ViewsComponent,
    EditProfileComponent,
    // CodeGenerationComponent,
    // BroadcastNotificationComponent,
    // SubZoneCoordinatesComponent,
    // ZoneCoordinatesComponent
  ],

  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    SharedModule,
    ImageCropperModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    ModalModule.forRoot(),
    CustomFormsModule,
    NgxSliderModule,
    SocketIoModule.forRoot(config),
    NgxIntlTelInputModule,
    MatSelectModule,

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthGuard,
    AuthService,
    SharedService,
    DashboardService,
    SessionService,
    ManageCaregiversService,
    ManageClientsService,
    ManageService,
    ManageConvenienceService,
    CodegenerationService,
    ManageGenresService,
    ManageCommunityService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
