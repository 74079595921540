

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()

export class ManageCaregiversService {
    [x: string]: any;

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getNotificationList(data: any) {
        return this.http.post(this.url + 'notification/getNotifications', data);
    }

    createNotification(data: any) {
        return this.http.post(this.url + 'notification/saveNotification', data);
    }

    deleteNotification(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data._id
        };
        return this.http.delete(this.url + 'api/post/delete-post/'+ data._id, httpOptions);
    }

    updateNotification(data : any) {
        return this.http.post(this.url + "notification/updateNotification", data);
    }


    getCaregiverList(data:any){
        return this.http.get(this.url + "api/post/reported-post/list", data);
    }

    approveCaregiver(data:any){
        return this.http.post(this.url + "user/approve-caregiver", data);
    }

    getCaregiverById(data:any){
        return this.http.post(this.url + "user/view-caregiver", data);
    }

    setStatus(data:any){
        return this.http.post(this.url + "user/change-status", data);
    }

    // getPostList(data:any){
    //     return this.http.get(this.url + `api/post/reported-post/list`, data);
    // }
    getPostList(pageNumber, limit){
        const url = `${this.url}api/post/reported-post/list?pageNumber=${pageNumber}&limit=${limit}`;
        return this.http.get(url);
    }

    // getUserData(data:any){
    //     return this.http.get(this.url + "api/user/reported-user/list", data);
    // }
    getUserData(pageNumber, limit){
        const url = `${this.url}api/user/reported-user/list?pageNumber=${pageNumber}&limit=${limit}`;
        return this.http.get(url);
    }

    getBlockedUsersData(pageNumber, limit){
        const url = `${this.url}api/user/reported-user/list?pageNumber=${pageNumber}&limit=${limit}`;
        return this.http.get(url);
    }

    // getCommentsData(data:any){
    //     return this.http.get(this.url + "api/comment/reported-comment-list", data);
    // }
    getCommentsData(pageNumber, limit){
        var apiUrl
        if(pageNumber && limit) {
            apiUrl = `${this.url}api/comment/reported-comment-list?pageNumber=${pageNumber}&limit=${limit}`;
        } else {
            apiUrl = `${this.url}api/comment/reported-comment-list`;
        }
     
        return this.http.get(apiUrl);
    }

    getRepliedData(pageNumber, limit){
        var apiUrl
        if(pageNumber && limit) {
            apiUrl = `${this.url}api/comment/reply-report-list?pageNumber=${pageNumber}&limit=${limit}`;
        } else {
            apiUrl = `${this.url}api/comment/reply-report-list`;
        }
     
        return this.http.get(apiUrl);
    }

    getallow(id: string) {
        const url = `${this.url}api/post/allow/${id}`;
        return this.http.put(url, null);
       
      }

      usersallow(id: string) {
        console.log("usersallow: " + id); // log the id to see if it's being passed correctly
        return this.http.put(`${this.url}api/user/allow/${id}`, null);
      }
      
      commentsallow(id: string) {
        console.log("commentsallow: " + id); // log the id to see if it's being passed correctly
        return this.http.put(`${this.url}api/comment/allow/${id}`, null);
      }

      repliesallow(id: string) {
        console.log("commentsallow: " + id); // log the id to see if it's being passed correctly
        return this.http.put(`${this.url}api/comment/allow-reply/${id}`, null);
      }

    //   usersreportedallow(id: string) {
    //     console.log("usersallow: " + id); // log the id to see if it's being passed correctly
    //     return this.http.put(`${this.url}api/user/allow/${id}`, null);
    //   }
      
      commentsreportedallow(id: string) {
        console.log("commentsallow: " + id); // log the id to see if it's being passed correctly
        return this.http.put(`${this.url}api/comment/allow/${id}`, null);
      }
      



    getPostDetailsById(id: string) { 
        return this.http.get(`${this.url}api/post/reported-post-details/${id}`);
      }

      getUserDetailsById(id: string) { 
        return this.http.get(`${this.url}api/user/reported-user-details/${id}`);
      }

      getReplyDetailsById(id: string) { 
        return this.http.get(`${this.url}api/comment/reply-report-details/${id}`);
      }

      getCommentDetailsById(id: string) { 
        return this.http.get(`${this.url}api/comment/comment-reported-details/${id}`);
      }

    
   

    
    DeletePostData(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body:data
        };
        return this.http.delete(this.url + 'api/post/delete-post/'+ data, httpOptions);
    }

     
    DeleteCommentsData(id: any, userId: any) {
        const httpOptions = {
          headers: new HttpHeaders({}),
          body: id
        };
        // return this.http.delete(this.url + 'api/comment/delete-comment/' + data + '/userId=' + userId, httpOptions);
        return this.http.delete(`${this.url}api/comment/delete-comment/${id}/${userId}`, httpOptions);

      }

    
    
    getManageBandsList(data) {
        return this.http.get(this.url + "api/user/bands-list", data)
    }
    DeleteUsersData(data: any,payload: any) {
        // const httpOptions = {
        //     headers: new HttpHeaders({}), body:data
        // };
        return this.http.put(this.url + 'api/user/block/'+ data, payload, );
    }

    ReportedUsersData(data: any,payload: any) {
        // const httpOptions = {
        //     headers: new HttpHeaders({}), body:data
        // };
        return this.http.put(this.url + 'api/user'+ data, payload, );
    }

    

}