<app-layout></app-layout>
<div class="content">
    <div class="row mt-4">
        <div class="total-counts">
            <div class="total-count total-users col-lg-5 mt-3"
                routerLink="/analytics-dashboard/registered-experiencers">
                <img src="../../../assets/Indie new images/SVG_LOGO/SVG_LOGO/Active age group.svg" alt="" height="60" width="60">
                <div>
                    <h2 id="client">{{artistCount}}</h2>
                    <p>Total Number of Registered Artists</p>

                </div>
            </div>
            <div class="total-count total-careers  col-lg-5 mt-3"
            routerLink="/analytics-dashboard/total-revenue">
                <img src="../../../assets/Indie new images/SVG_LOGO/SVG_LOGO/Downloads.svg" alt="" height="60" width="60">
                <div>
                    <h2 id="client1">{{communityCount}}</h2>
                    <p>Total Number of Registered Community</p>
                </div>
            </div>
            

            <div class="total-count total-careers  col-lg-5 mt-3">
                <img src="../../../assets/Indie new images/SVG_LOGO/SVG_LOGO/Devices.svg" alt="" height="60" width="60">
                <div>
                    <h2 id="client1">{{postcount}}</h2>
                    <p>Total Number of Posts</p>
                </div>
            </div>



            <div class="total-count total-careers  col-lg-5 mt-3" >
                <img src="../../../assets/Indie new images/SVG_LOGO/SVG_LOGO/Active age group.svg" alt="" height="60" width="60">
                <div>
                    <h2 id="client1"> {{showCount}}</h2>
                    <p>Total Number of Shows</p>

                </div>
            </div>
    </div>
</div>