// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // API_URL: "https://jeddahapi.appstudiointernal.ca/api/", // development
  // API_URL: "http://192.168.0.81:5000/v1/",
  // API_URL: "https://bg-api.appstudiointernal.ca/v1/",

  


  // API_URL: "https://stg-indietown-api.approd.ca/",
  API_URL: "https://indietown-api.approd.ca/",   // indieTown api
  secure:false,



  

  



  

  // API_URL: "https://devapi.approd.ca/v1/",
  
  // API_URL: "https://bg-api.appstudiointernal.ca/v1/",

  

  // API_URL: "http://43.204.1.155:5000/api/", // development
  // API_URL: "http://localhost:5000/api/", // development
  // API_URL: 'https://devapi.riyadhseason.sa/api/', // development
  // API_URL: 'https://devapi.riyadhseason.sa/api/',// redish dev
  // BaseChatUrl: "http://ec2-13-234-249-108.ap-south-1.compute.amazonaws.com:3201", // development
  // BaseChatUrl: "https://chatriyadhseason.appstudiointernal.ca", // server
  BaseChatUrl: "https://chat.riyadhseason.sa", // production server

  // API_URL: 'https://devapi.riyadhseason.sa/api/', // Testing
  // API_URL: 'https://api.riyadhseason.sa/api/', // Production
  // API_URL: 'http://backend-prod-svc:3200/api/'

  // API_URL:
  //   'http://ec2-13-234-249-108.ap-south-1.compute.amazonaws.com:3200/api/',
  // API_URL: 'http://localhost:3100/api/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
